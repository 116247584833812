<template>
  <div class="home">
    <h1>李诗晨官方网站</h1>
    <h2>网站升级维护中</h2>
  </div>
</template>

<script>
 

export default {
  name: 'HomeView',
  components: {

  }
}
</script>
